import "./styles.css"

import React, { useEffect, useState } from "react"
import { useQuery } from "@apollo/client"
import { FeaturesQuery } from "../../services/graphql"

const Features = () => {
  const { data, loading, error } = useQuery(FeaturesQuery)
  const [sortedFeatures, setSortedFeatures] = useState([])
  useEffect(() => {
    // let resData = data?.features
    if (data) {
      var resData = [...data?.features]
      const sortedFeaturesData = resData?.sort((a, b) =>
        parseInt(a.imagePosition) > parseInt(b.imagePosition) ? 1 : -1
      )
      setSortedFeatures(sortedFeaturesData)
    }
  }, [data])

  const imageUrl = "https://wwf-images.us-east-1.linodeobjects.com/wwf-images/"
  let featuresData = data?.features

  return (
    <>
      <div className="feature_container">
        <div className="featuretxts">
          <div className="featuretxt1">Features</div>
          <div className="featuretxt2">
            <p>
              {/* Lorem ipsum is common placeholder text used to demonstrate the */}
              {/* graphic elements of a document or visual presentation. */}
            </p>
          </div>
        </div>
        <div className="featuregrids">
          {sortedFeatures?.map((featureDate) => {
            return (
              <div className="gridcont">
                <img src={imageUrl + featureDate?.image} alt="" />
                <div className="gridtxt1">{featureDate?.name}</div>
                <div className="gridtxt2">{featureDate?.description}</div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default Features
