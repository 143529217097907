import React from 'react'
import { useNavigate } from 'react-router-dom'
import "./NotFound.css"

function NotFoundPage() {
    const navigate = useNavigate()

    return (
        <>
            <div className="notFoundContainer">
                <div className="title">404</div>
                <div className="text">Ooops, Page Not Found</div>
                <div onClick={() => navigate('/')} className="goHome">
                    Home Page
                </div>
            </div>
        </>
    )
}

export default NotFoundPage