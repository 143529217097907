import React from "react"
import "./style.css"

interface props {
  name: string
  //   type: string
  onChange: (e) => void
  value: string
  placeholder: string
}
const TextAreafield = ({ name, onChange, value, placeholder }: props) => {
  return (
    <>
      <textarea
        // type={type}
        name={name}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        className="textareafield"
      />
    </>
  )
}

export default TextAreafield
