import "./Header.css"
import { AiOutlineCloseCircle, AiOutlineUnorderedList } from "react-icons/ai"
import { useState } from "react"
import { Link } from "react-router-dom"

const Header = ({ contactUs, home, feature }) => {
  const [menu, setmenu] = useState(false)
  return (
    <>
      <div className="mainheader">
        <div className="mainheaderlogo">
          <Link style={{ textDecoration: "none", color: "inherit" }} to={"/"}>
            <div className="titleLogo">
            <img src="/apslogo.jpeg" alt="" />
            <p>Scheduling Solution</p>
            </div>
          </Link>
        </div>
        <div className="mainheaderlist">
          <div onClick={home} className="headerli">
            Home
          </div>
          <div onClick={feature} className="headerli">
            Features
          </div>
          <div onClick={contactUs} className="headerli">
            Contact Us
          </div>
          {/* <div className="headerli">Jobs</div> */}
          {/* <div className="headerli">
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to={"/Contact"}
            >
              Contact Us
            </Link>
          </div>
          <div className="headerli">More</div> */}
        </div>

        <div className="mainheaderlistmobile">
          <div onClick={() => setmenu(!menu)} className="mobheader">
            <AiOutlineUnorderedList />
          </div>

          <div className="menurel">
            <div
              style={{
                left: menu && "40%",
              }}
              className="menuabs"
            >
              <div onClick={() => setmenu(!menu)} className="closeicon">
                <AiOutlineCloseCircle />
              </div>
              <div className="mobmenulist">
                <div className="headerli">Home</div>
                <div className="headerli">Features</div>
                <div className="headerli">Contact Us</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
