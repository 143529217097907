import Hero from "./components/HeroSection/Hero"
import React, { useRef } from "react"
import "./App.css"
import Headers from "./components/Header/Header"
import Features from "./components/Features/Features"
import Footer from "./components/Footer/Footer"
import ContactComp from "./components/Contact/Contactus"

function Home() {
  const home = useRef(null)
  const feature = useRef(null)
  const contactUs = useRef(null)

  const handleHome = () => {
    home.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    })
  }
  const handleFeature = () => {
    let topOffset = feature.current.offsetTop - 100
    window.scrollTo({
      behavior: "smooth",
      top: topOffset,
    })
    // feature.current.scrollIntoView({
    //   block: "start",
    //   behavior: "smooth",
    // })
  }
  const handleContactUs = () => {
    let topOffset = contactUs.current.offsetTop - 100
    window.scrollTo({
      behavior: "smooth",
      top: topOffset,
    })
    // contactUs.current.scrollIntoView({
    //   block: "start",
    //   behavior: "smooth",
    // })
  }

  return (
    <div className="Home">
      <div
        style={{
          position: "fixed",
          width: "100%",
          zIndex: '1'
        }}
      >
        <Headers
          home={handleHome}
          feature={handleFeature}
          contactUs={handleContactUs}
        />
      </div>
      <div ref={home} className="">
        <Hero />
      </div>
      {/* <HostSection />
      <Embedded /> */}
      {/* <TrustedClients /> */}
      <div ref={feature}>
        <Features />
      </div>
      {/* <Stories />
      <Achievements />
      <Headline />
      <Scheduling />
      <Manage /> */}
      <div ref={contactUs}>
        <ContactComp />
      </div>
      <Footer
        home={handleHome}
        feature={handleFeature}
        contactUs={handleContactUs}
      />
    </div>
  )
}

export default Home
