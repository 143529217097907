import HomeButton from "../Buttons/HomeButton"
import React, { useRef, useState } from "react"
import "./hero.css"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { AiOutlineArrowDown, AiFillCaretRight } from "react-icons/ai"

const Hero = () => {
  const notify = () => navigate("/Contact")
  const navigate = useNavigate()
  const videoRef = useRef(null)

  const [playStatus, setPlayStatus] = useState(false)

  return (
    <>
      <div className="videoparent">
        <video
          onClick={() => {
            // videoRef.current.pause()
            setPlayStatus(false)
          }}
          ref={videoRef}
          width="750"
          height="500"
          controls
          poster="/aps.png"
          style={{
            opacity: !playStatus && "0.2"
          }}
          controlsList="nodownload"
        >
          <source src="APS_Updated_9.56.38.mp4" type="video/mp4" />
        </video>
        {!playStatus && (
          <div className="videotxt">
            <h1>Why Scheduling Solution?</h1>
            {/* <div className="videoplaytxt">
              <div className="">Click to Play</div>
              <AiOutlineArrowDown />
            </div> */}
            <div className="playicon">
              <AiFillCaretRight
                // className=""
                onClick={() => {
                  setPlayStatus(true)
                  videoRef.current.play()
                }}
              />
            </div>
          </div>
        )}
      </div>
      {/* <div className="playimgbtncont">
        <div className="playbtn">
          <img
            onClick={() => videoRef.current.video.play()}
            src="play-button-28259.png"
            alt=""
          />
        </div>
      </div> */}
      {/*  <section>
        <div className="section1">
          <div className="">
            <p>
              Organise projects.<br></br> Get more done.
            </p>
            <div className="btnconter1">
              <HomeButton onClick={notify} name={"Get Started"} />
            </div>
          </div>
        </div>
        <div className="section2">
          <img src="45999.jpg" alt="" />
        </div> 
      </section>*/}
    </>
  )
}

export default Hero
