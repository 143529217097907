import React from "react"
import "./style.css"

const Footer = ({ contactUs, home, feature }) => {
  return (
    <>
      <div className="footer">
        <div className="footmain">
          <div className="footerlist">
            <li onClick={home}>Home</li>
            <li onClick={feature}>Features</li>
            <li onClick={contactUs}>Contact Us</li>
          </div>
          <div className="footercp">
            © 2022 Scheduling Solution. All rights reserved.
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
