import React from "react"
import Headers from "../Header/Header"
import ContactComp from "./Contactus"
import './style.css'


function Contact() {
  return (
    <>
      <div className="contactmain">
        {/* <Headers /> */}
        <ContactComp />
      </div>
    </>
  )
}

export default Contact
