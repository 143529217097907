import { useMutation } from "@apollo/client"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { createEnquire } from "../../services/graphql"
// import { createEnquire } from "services/graphql"
import Button from "./Button"
import Inputfield from "./Inputfield"
import "./style.css"
import TextAreafield from "./TextAreafield"

export const ValidateEmail = (v) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(v).toLowerCase())
}

export const OnlyNumbers = (v) => {
  var regExp = /[a-zA-Z]/g
  var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
  if (regExp.test(v) || format.test(v)) {
    return true
  }
  return false
}

function ContactComp() {
  const [registervalues, setRegistervalues] = useState({
    name: "",
    email: "",
    phone: "",
    des: "",
    cname: "",
    // aname: "",
  })
  const navigate = useNavigate()
  const [errors, setErrors] = useState({
    errortxt: "",
    errorname: "",
  })

  const [call] = useMutation(createEnquire)

  const emailreset = () => {
    setErrors({
      ...errors,
      errortxt: "",
      errorname: "",
    })
  }

  const datareset = () => {
    setRegistervalues({
      ...registervalues,
      name: "",
      email: "",
      phone: "",
      des: "",
      cname: "",
      // aname: "",
    })
  }

  const handlefields = (e) => {
    setRegistervalues({ ...registervalues, [e.target.name]: e.target.value })
  }

  const handleemail = (e) => {
    setRegistervalues({ ...registervalues, [e.target.name]: e.target.value })
    if (e.target.value?.length > 0 && !ValidateEmail(e.target.value)) {
      setErrors({
        ...errors,
        errortxt: "Incorrect Email",
        errorname: e.target.name,
      })
    } else {
      emailreset()
    }
  }

  const handlenumbers = (e) => {
    setRegistervalues({ ...registervalues, [e.target.name]: e.target.value })
    if (e.target.value?.length > 0 && OnlyNumbers(e.target.value)) {
      setErrors({
        ...errors,
        errortxt: "Incorrect Number",
        errorname: e.target.name,
      })
    } else {
      emailreset()
    }
  }

  const handleclick = () => {

    if (!ValidateEmail(registervalues.email)) {
      setErrors({
        ...errors,
        errortxt: "Incorrect Email",
        errorname: 'email',
      })
      return null
    }
    if (OnlyNumbers(registervalues.phone)) {
      setErrors({
        ...errors,
        errortxt: "Incorrect Number",
        errorname: 'phone',
      })
      return null
    }
    if (
      registervalues.name !== "" &&
      // registervalues.aname !== "" &&
      registervalues.cname !== "" &&
      registervalues.phone !== "" &&
      registervalues.des !== "" &&
      registervalues.email !== ""
    ) {
      call({
        variables: registervalues,
      })
        .then((r) => {
          emailreset()
          console.log("message sent")
          datareset()
          toast.success("Thank you for Contacting us")
          navigate("/")
        })
        .catch((e) => {
          setErrors({
            ...errors,
            errortxt: e.message,
            errorname: "allfields",
          })
        })
    } else {
      setErrors({
        ...errors,
        errortxt: "Please fill all the fields",
        errorname: "allfields",
      })
    }
  }
  return (
    <>
      <div className="contactpage">
        <div className="form">
          <p>Contact Us</p>
          <div className="formfields">
            {errors.errorname === "allfields" && (
              <>
                <span className="error">{errors.errortxt}</span>
              </>
            )}
            <div className="grid">
              {/* <div className="gridlabel">Company Name</div> */}
              <div className="gridinput">
                <Inputfield
                  name="cname"
                  placeholder="Company Name"
                  type="text"
                  value={registervalues.cname}
                  onChange={handlefields}
                />
              </div>
            </div>
            <div className="grid">
              {/* <div className="gridlabel">Full Name</div> */}
              <div className="gridinput">
                <Inputfield
                  name="name"
                  placeholder="Name"
                  type="text"
                  value={registervalues.name}
                  onChange={handlefields}
                />
              </div>
            </div>
            <div className="grid">
              {/* <div className="gridlabel">Email</div> */}
              <div className="gridinput">
                <Inputfield
                  name="email"
                  placeholder="Email"
                  type="text"
                  value={registervalues.email}
                  onChange={handleemail}
                />
                <div className="errorContainer">
                  {errors.errorname === "email" && (
                    <>
                      <span className="error">{errors.errortxt}</span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="grid">
              {/* <div className="gridlabel">Contact No</div> */}
              <div className="gridinput">
                <Inputfield
                  name="phone"
                  placeholder="Phone Number"
                  type="text"
                  value={registervalues.phone}
                  onChange={handlenumbers}
                />
                <div className="errorContainer">
                  {errors.errorname === "phone" && (
                    <>
                      <span className="error">{errors.errortxt}</span>
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* <div className="grid">
              <div className="gridlabel">Applier Name</div>
              <div className="gridinput">
                <Inputfield
                  name="aname"
                  placeholder="Enter Your Applier Name"
                  type="text"
                  value={registervalues.aname}
                  onChange={handlefields}
                />
              </div>
            </div> */}
            <div className="grid">
              {/* <div className="gridlabel">Discription</div> */}
              <div className="gridinput">
                <TextAreafield
                  name="des"
                  placeholder="Description"
                  value={registervalues.des}
                  onChange={handlefields}
                />
              </div>
            </div>
            <div className="grid">
              <div className="gridlabel"></div>
              <div className="gridinput">
                <Button name="Submit" onClick={handleclick} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactComp
