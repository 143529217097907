import React from "react"
import "./style.css"

interface props {
  name: string
  onClick: () => void
}
const Button = ({ name, onClick }: props) => {
  return (
    <>
      <button className="btn" onClick={onClick}>
        {name}
      </button>
    </>
  )
}

export default Button
