import {
  ApolloClient, ApolloLink, ApolloProvider, concat, HttpLink, InMemoryCache
} from "@apollo/client"
import NotFoundPage from "NotFound"
import React, { useEffect } from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import TawkTo from "tawkto-react"
import "./App.css"
// import Home from "Home"
import Contact from "./components/Contact/ContactIndex"
import Home from "./Home"


let link = new HttpLink({
  // uri: "http://127.0.0.1:8000/graphql/",
  // uri: "/graphql/",
  // uri: "http://wanderwithfood.com/graphql/",
  uri: "https://api.schedulingsolution.com/graphql/",
})

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  // let token = localStorage.getItem("token")
  operation.setContext({
    headers: {
      // authorization: token ? `JWT ${token}` : "",
      "Content-type": "application/json"
    },
  })

  return forward(operation)
})

let client = new ApolloClient({
  link: concat(authMiddleware, link),
  cache: new InMemoryCache(),
})



function App() {
  useEffect(() => {
    var tawk = new TawkTo("61fd5a07b9e4e21181bd8363", "1fr2qfcq6")

    tawk.onStatusChange((status) => {
      // console.log(status)
    })
    tawk.showWidget()
  }, [])
  return (
    <>
      <ApolloProvider client={client}>
        <Router>
          <Routes>
            {/* <Route exact path="/" component={Home} /> */}
            <Route path="/" element={<Home />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/404" element={<NotFoundPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
        <ToastContainer />
      </ApolloProvider>
    </>
  )
}

export default App
