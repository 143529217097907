import { gql } from "@apollo/client"

export const createEnquire = gql`
  mutation createEnquire(
    # $aname: String!
    $cname: String!
    $des: String!
    $email: String!
    $name: String!
    $phone: String!
  ) {
    createEnquire(
      # aname: $aname
      cname: $cname
      des: $des
      email: $email
      name: $name
      phone: $phone
    ) {
      success
    }
  }
`
export const FeaturesQuery = gql`
  query features {
    features {
      id
      name
      description
      image
      imagePosition
    }
  }
`
