import React from "react"
import "./style.css"

interface props {
  name: string
  type: string
  onChange: (e) => void
  value: string
  placeholder: string
}
const Inputfield = ({ name, type, onChange, value, placeholder }: props) => {
  return (
    <>
      <input
        type={type}
        name={name}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        className="field"
        autoComplete="off"
      />
    </>
  )
}

export default Inputfield
